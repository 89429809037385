import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { ToastContainer } from '~module/toast';
import { store } from './store/make-store';
import ErrorBoundary from './error-boundary';
import './i18n';
import Main from './main';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
    },
  },
});

function App() {
  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <Main />
          <ToastContainer />
        </Provider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

export default App;
