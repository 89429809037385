import React from 'react';
import classNames from 'classnames';
import WinnerDto from '~dto/winner-dto';
import Coin from '~component/coin/coin';
import { currency, shortAddress } from '~util/base';

interface WinnerItemProps {
  item: WinnerDto;
}

function WinnerItem({ item }: WinnerItemProps) {
  return (
    <tr>
      <td>{shortAddress(item.player)}</td>
      <td>
        {currency(item.betAmount)} <Coin type={item.betCoin} />
        <span className={classNames(['ico', item.betCoin])}></span>
      </td>
      <td className={classNames(item.profitAmount > 0 ? 'win' : 'lose')}>
        {item.profitAmount > 0 ? '+' : '-'}
        {currency(item.profitAmount)} <Coin type={item.profitCoin} />
      </td>
    </tr>
  );
}

export default WinnerItem;
