import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UiState {
  isBetting: false | UiOnBettingPayload;
}

interface UiOnBettingPayload {
  isUp: boolean;
}

const initialState: UiState = {
  isBetting: false,
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    onBetting: (state, { payload }: PayloadAction<UiOnBettingPayload>) => {
      state.isBetting = payload;
    },
    offBetting: (state) => {
      state.isBetting = false;
    },
  },
});

export const { onBetting, offBetting } = uiSlice.actions;

export default uiSlice.reducer;
