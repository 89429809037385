import HistoryDataNone from './history-data-none';
import useHistoryData from './history-data.hook';
import HistoryRow from './history-row';
import './history.scss';

interface HistoryDataProps {
  size: number;
}

function HistoryData({ size }: HistoryDataProps) {
  const { data } = useHistoryData();

  return (
    <>
      {(data?.length ?? 0) > 0 ? data?.map((e) => <HistoryRow key={e.date} {...e} />) : <HistoryDataNone size={size} />}
    </>
  );
}

export default HistoryData;
