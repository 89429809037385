import type { CoinType } from '~types';

export default class LiveBetDto {
  static #symbol = Symbol();

  #player: string;
  get player() {
    return this.#player;
  }

  #betCoin: CoinType;
  get betCoin() {
    return this.#betCoin;
  }
  #betAmount: number;
  get betAmount() {
    return this.#betAmount;
  }

  constructor(symbol: symbol, player: string, betCoin: CoinType, betAmount: number) {
    if (symbol !== LiveBetDto.#symbol) {
      throw new Error('');
    }

    this.#player = player;
    this.#betCoin = betCoin;
    this.#betAmount = betAmount;
  }

  static create(player: string, betCoin: CoinType, betAmount: number) {
    return new LiveBetDto(LiveBetDto.#symbol, player, betCoin, betAmount);
  }
}
