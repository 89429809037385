import type { CoinType } from '~types';

export default class WinnerDto {
  static #symbol = Symbol();

  #player: string;
  get player() {
    return this.#player;
  }

  #betCoin: CoinType;
  get betCoin() {
    return this.#betCoin;
  }
  #betAmount: number;
  get betAmount() {
    return this.#betAmount;
  }

  #profitCoin: CoinType;
  get profitCoin() {
    return this.#profitCoin;
  }
  #profitAmount: number;
  get profitAmount() {
    return this.#profitAmount;
  }

  constructor(
    symbol: symbol,
    player: string,
    betCoin: CoinType,
    betAmount: number,
    profitCoin: CoinType,
    profitAmount: number,
  ) {
    if (symbol !== WinnerDto.#symbol) {
      throw new Error('');
    }

    this.#player = player;
    this.#betCoin = betCoin;
    this.#betAmount = betAmount;
    this.#profitCoin = profitCoin;
    this.#profitAmount = profitAmount;
  }

  static create(player: string, betCoin: CoinType, betAmount: number, profitCoin: CoinType, profitAmount: number) {
    return new WinnerDto(WinnerDto.#symbol, player, betCoin, betAmount, profitCoin, profitAmount);
  }
}
