import { useCallback, useState } from 'react';
import { toast } from '~module/toast';
import { gameBetting } from '~store/game-bet';
import { connectGameParticipantList } from '~store/game-participants';
import { connectGameTotalBetAmount } from '~store/game-total-amount';
import { useAppDispatch, useGameInfo, useWalletInfo } from '~store/make-store';
import { offBetting } from '~store/ui';
import { BetPriceProps } from './bet-price';

const useBetPrice = ({ isUp }: BetPriceProps) => {
  const appDispatch = useAppDispatch();
  const { balance, coin } = useWalletInfo() ?? { balance: 0, coin: 'usdt' };
  const defaultPrice = useGameInfo()?.betAmount ?? 0;
  const [selectedMultiple, setSelectedMultiple] = useState<number>(1);
  const [isBetActive, setBetActive] = useState<boolean>(false);

  const onClicClose = () => {
    appDispatch(offBetting());
  };

  const onClickChoice = (multiple: number) => {
    setSelectedMultiple(multiple);
  };

  const onClickBet = useCallback(() => {
    setBetActive(true);
    toast.dismise();
    toast.loading('Waiting your confirmation in your wallet');

    appDispatch(gameBetting(selectedMultiple * (isUp ? 1 : -1)))
      .unwrap()
      .then(async () => {
        await appDispatch(connectGameParticipantList());
        await appDispatch(connectGameTotalBetAmount());
      })
      .then(() => {
        toast.dismise();
        toast.sucess('Transaction is successfully sended', {
          onClose: () => {
            setBetActive(false);
            appDispatch(offBetting());
          },
        });
      })
      .catch((err) => {
        console.log(err);

        toast.dismise();
        toast.error('Transaction is rejected. Please try again', {
          onClose: () => {
            setBetActive(false);
            appDispatch(offBetting());
          },
        });
      });
  }, [appDispatch, selectedMultiple, isUp]);

  return {
    isBetActive,
    balance,
    coin,
    defaultPrice,
    selectedMultiple,
    onClicClose,
    onClickChoice,
    onClickBet,
  };
};

export default useBetPrice;
