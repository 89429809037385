import classNames from 'classnames';

interface HistoryRowProps {
  date: string;
  opened: string;
  closed: string;
  netChange: string;
}

function HistoryRow({ date, opened, closed, netChange }: HistoryRowProps) {
  const upDownClassName = parseFloat(netChange) > 0 ? 'up' : 'down';

  return (
    <tr>
      <td>{date}</td>
      <td>{opened}</td>
      <td className={classNames(upDownClassName)}>{closed}</td>
      <td>
        {netChange}
        <span className={classNames('ico', upDownClassName)}></span>
      </td>
    </tr>
  );
}

export default HistoryRow;
