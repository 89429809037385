import type { AbiItem, StateMutabilityType } from 'web3-utils';

export const GameContractAddress = import.meta.env.VITE_GAME_CONTRACT_ADDRESS;

export default [
  {
    inputs: [
      {
        internalType: 'address',
        name: '_tokenAddress',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '_betAmount',
        type: 'uint256',
      },
    ],
    stateMutability: 'nonpayable' as StateMutabilityType,
    type: 'constructor',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint8',
        name: 'round',
        type: 'uint8',
      },
      {
        indexed: false,
        internalType: 'uint8',
        name: 'cnt',
        type: 'uint8',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'player',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'bool',
        name: 'isHigh',
        type: 'bool',
      },
    ],
    name: 'Bet',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousOwner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    inputs: [
      {
        internalType: 'uint8',
        name: 'betRound',
        type: 'uint8',
      },
      {
        internalType: 'uint8',
        name: 'cnt',
        type: 'uint8',
      },
      {
        internalType: 'bool',
        name: 'isHigh',
        type: 'bool',
      },
    ],
    name: 'createBet',
    outputs: [],
    stateMutability: 'payable' as StateMutabilityType,
    type: 'function',
  },
  {
    inputs: [],
    name: 'getChargeAddress',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view' as StateMutabilityType,
    type: 'function',
  },
  {
    inputs: [],
    name: 'getGame',
    outputs: [
      {
        components: [
          {
            internalType: 'uint8',
            name: 'round',
            type: 'uint8',
          },
          {
            internalType: 'uint256',
            name: 'betAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint8',
            name: 'bettingStatus',
            type: 'uint8',
          },
          {
            internalType: 'address',
            name: 'usdtTokenAddress',
            type: 'address',
          },
          {
            internalType: 'uint8',
            name: 'usdtTokenDecimals',
            type: 'uint8',
          },
          {
            internalType: 'uint8',
            name: 'rate',
            type: 'uint8',
          },
          {
            internalType: 'bool',
            name: 'isHigh',
            type: 'bool',
          },
        ],
        internalType: 'struct Konegi.Game',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view' as StateMutabilityType,
    type: 'function',
  },
  {
    inputs: [],
    name: 'getParticipants',
    outputs: [
      {
        components: [
          {
            internalType: 'uint8',
            name: 'round',
            type: 'uint8',
          },
          {
            internalType: 'uint8',
            name: 'cnt',
            type: 'uint8',
          },
          {
            internalType: 'address',
            name: 'player',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'orgAmount',
            type: 'uint256',
          },
          {
            internalType: 'bool',
            name: 'isHigh',
            type: 'bool',
          },
        ],
        internalType: 'struct Konegi.Participant[]',
        name: '',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view' as StateMutabilityType,
    type: 'function',
  },
  {
    inputs: [],
    name: 'getTotalBetAmount',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view' as StateMutabilityType,
    type: 'function',
  },
  {
    inputs: [],
    name: 'getWinners',
    outputs: [
      {
        components: [
          {
            internalType: 'uint8',
            name: 'round',
            type: 'uint8',
          },
          {
            internalType: 'uint8',
            name: 'cnt',
            type: 'uint8',
          },
          {
            internalType: 'address',
            name: 'player',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'orgAmount',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'winnerAmount',
            type: 'uint256',
          },
          {
            internalType: 'bool',
            name: 'isHigh',
            type: 'bool',
          },
          {
            internalType: 'bool',
            name: 'isPayable',
            type: 'bool',
          },
        ],
        internalType: 'struct Konegi.Winner[]',
        name: '',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view' as StateMutabilityType,
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view' as StateMutabilityType,
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bool',
        name: '_isHigh',
        type: 'bool',
      },
      {
        internalType: 'uint8',
        name: '_rate',
        type: 'uint8',
      },
    ],
    name: 'pickWinner',
    outputs: [],
    stateMutability: 'nonpayable' as StateMutabilityType,
    type: 'function',
  },
  {
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable' as StateMutabilityType,
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_betAmount',
        type: 'uint256',
      },
    ],
    name: 'reset',
    outputs: [],
    stateMutability: 'nonpayable' as StateMutabilityType,
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint8',
        name: '_bettingStatus',
        type: 'uint8',
      },
    ],
    name: 'setBettingStatus',
    outputs: [],
    stateMutability: 'nonpayable' as StateMutabilityType,
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address payable',
        name: '_chargeAddress',
        type: 'address',
      },
    ],
    name: 'setChargeAddress',
    outputs: [],
    stateMutability: 'nonpayable' as StateMutabilityType,
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address payable',
        name: 'to',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '_amount',
        type: 'uint256',
      },
    ],
    name: 'transfer',
    outputs: [],
    stateMutability: 'nonpayable' as StateMutabilityType,
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable' as StateMutabilityType,
    type: 'function',
  },
  {
    stateMutability: 'payable' as StateMutabilityType,
    type: 'receive',
  },
] as AbiItem[];
