import CountUp from 'react-countup';
import { useGameInfo, useGameTotalBetAmount, useGameWinners } from '~store/make-store';
import './betting-info.scss';
import BettingLeftTime from './betting-left-time';

function BettingInfo() {
  const gameInfo = useGameInfo();
  const bettingStatus = gameInfo?.bettingStatus;
  const winner = useGameWinners();
  let totalBetAmount = useGameTotalBetAmount() ?? 0;
  if (bettingStatus === 'calculating') {
    const profitAmountTotal = winner?.reduce((prev, curr) => prev + curr.profitAmount, 0) ?? 0;
    const betAmountTotal = winner?.reduce((prev, curr) => prev + curr.betAmount, 0) ?? 0;

    totalBetAmount = (profitAmountTotal / 9) * 10 - (betAmountTotal / 9) * 10 + betAmountTotal;
  }

  return (
    <div id="betting-info">
      <p>TOTAL POT</p>
      <p className="price">$ {<CountUp duration={0.5} decimal="," start={0} end={totalBetAmount} />}</p>
      <p className="time">
        {bettingStatus === 'betting' ? (
          <>
            The bet closes in <BettingLeftTime />. Hurry up!😎
          </>
        ) : bettingStatus === 'bet-done' ? (
          <>
            The results will be announced after <BettingLeftTime />. Good luck!🤞
          </>
        ) : (
          <>
            New bets start in <BettingLeftTime />. 🎉🎉
          </>
        )}
      </p>
    </div>
  );
}

export default BettingInfo;
