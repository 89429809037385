import { createAsyncThunk } from '@reduxjs/toolkit';
import { TokenContractDecimal } from '~def/token-contract-def';
import { setApiAllowance, setApiApprove, setApiBetting } from '~module/api';
import { type ThunkAPIConfig } from './util';
import { dividendDecimal, multipleDecimal } from '~util/base';
import { isGameInfo } from './game';
import { isWalletInfo } from './wallet';

export const gameBetting = createAsyncThunk<void, number, ThunkAPIConfig>(
  'gameBetting',
  async (cnt, { dispatch, getState }) => {
    const {
      game: { state: gameState },
      wallet: { state: walletState },
    } = getState();

    if (!isGameInfo(gameState)) {
      throw new Error('game info not loaded');
    }
    if (!isWalletInfo(walletState)) {
      throw new Error('wallet info not loaded');
    }

    const { round, betAmount } = gameState;
    const { address } = walletState;
    const allowance = await setApiAllowance(address);
    const parseAllowance = dividendDecimal(allowance, TokenContractDecimal);
    console.log('sss', parseAllowance, betAmount, Math.abs(cnt));

    if (parseAllowance < betAmount * Math.abs(cnt)) {
      await setApiApprove(address, cnt, multipleDecimal(betAmount, TokenContractDecimal));
    }
    await setApiBetting(address, round, cnt);
  },
);
