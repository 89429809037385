import { ActionReducerMapBuilder, AsyncThunk } from '@reduxjs/toolkit';
import { RootState } from './make-store';

export interface StateType<T> {
  state: AsyncType<T>;
}

export const commonInitState = <T>() => {
  return {
    state: 'idle',
  } as T;
};

export type AsyncType<T> = 'idle' | 'pending' | Error | T;

export const isSynced = <T>(value: unknown): value is T => {
  return !!value && value !== 'idle' && value !== 'pending' && !(value instanceof Error);
};

///////////////////////////////////////////////

export interface ThunkAPIConfig {
  state: RootState;
  rejectValue: Error;
}

export const extraReducersWrapper = <T, Arg = void>(
  asyncThunk: AsyncThunk<T, Arg, ThunkAPIConfig>,
  builder: ActionReducerMapBuilder<StateType<T>>,
) => {
  builder.addCase(asyncThunk.pending, (state) => {
    state.state = 'pending';
  });
  builder.addCase(asyncThunk.fulfilled, (state, { payload }) => {
    state.state = payload as any;
  });
  builder.addCase(asyncThunk.rejected, (state, { payload }) => {
    if (payload) {
      state.state = payload;
    }
  });
};
