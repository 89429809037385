import HistoryDataMsg, { HistoryDataMsgProps } from './history-data-msg';
import Loading from '~component/loading/loading';

type HistoryDataLoadingProps = Pick<HistoryDataMsgProps, 'size'>;

const HistoryDataLoading = ({ size }: HistoryDataLoadingProps) => {
  return (
    <HistoryDataMsg size={size}>
      <Loading />
    </HistoryDataMsg>
  );
};

export default HistoryDataLoading;
