import { useEffect, useState } from 'react';
import useInterval from 'beautiful-react-hooks/useInterval';
import { BettingStatus } from '~def/base';
import { useGameInfo } from '~store/make-store';
import { numToTimeStr } from '~util/base';

const getLeftTime = (bettingStatus: BettingStatus) => {
  const currentUTC = new Date().toUTCString();
  const currentDateUTC = new Date(currentUTC);
  let hour = 0;

  switch (bettingStatus) {
    case 'betting': // 0
      hour = 14;
      break;
    case 'bet-done': // 1
      hour = 20;
      break;
    case 'calculating': // 2
      hour = 22;
      break;
  }
  const targetTimeUTC = new Date(currentDateUTC);
  targetTimeUTC.setUTCHours(hour, 0, 0, 0);
  const timeDifferenceMillis = targetTimeUTC.getTime() - currentDateUTC.getTime();

  return timeDifferenceMillis / 1000;
};

function BettingLeftTime() {
  const gameInfo = useGameInfo();
  const bettingStatus = gameInfo?.bettingStatus;
  const [time, setTime] = useState(0);

  useEffect(() => {
    if (bettingStatus) {
      setTime(getLeftTime(bettingStatus));
    }
  }, [bettingStatus]);

  useInterval(() => {
    setTime(time - 1);
  }, 1000);

  return (
    <time>
      <strong>{time < 0 ? 'Wait for ...' : numToTimeStr(time)}</strong>
    </time>
  );
}

export default BettingLeftTime;
