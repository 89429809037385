import useLinkWallet from './link-wallet.hook';
import './link-wallet.scss';

function LinkWallet() {
  const { isPending, handleClickConnect } = useLinkWallet();

  const onClickConnect: React.MouseEventHandler = (e) => {
    e.preventDefault();
    handleClickConnect();
  };

  return (
    <div className="link-wallet">
      <button type="button" className="btn type2" onClick={onClickConnect} disabled={isPending}>
        <span className="txt">Connect Wallet</span>
      </button>
    </div>
  );
}

export default LinkWallet;
