import classNames from 'classnames';
import Coin from '~component/coin/coin';
import { useAppSelector } from '~store/make-store';
import { currency } from '~util/base';
import BetPriceItem from './bet-price-item';
import useBetPrice from './bet-price.hook';
import './bet-price.scss';

export interface BetPriceProps {
  isUp: boolean;
}

function BetPrice(props: BetPriceProps) {
  const { isUp } = props;
  const { isBetting } = useAppSelector(({ ui: { isBetting } }) => ({ isBetting }));
  const { isBetActive, balance, coin, defaultPrice, selectedMultiple, onClicClose, onClickChoice, onClickBet } =
    useBetPrice(props);

  const handleClicClose: React.MouseEventHandler = (e) => {
    e.preventDefault();
    onClicClose();
  };

  const handleClickBet: React.MouseEventHandler = (e) => {
    e.preventDefault();
    onClickBet();
  };

  return (
    <div id="bet-price" className={classNames({ isOpen: isBetting })}>
      <header>
        <h2>Bet your position</h2>
        <button type="button" onClick={handleClicClose}>
          <span className="ico close">close</span>
        </button>
      </header>
      <div className="body">
        <p>
          Your balance: <strong>{balance === 0 ? '-' : currency(balance)}</strong>
          <Coin type={coin} />
        </p>
        <ul>
          {[1, 5, 10].map((e) => (
            <BetPriceItem
              key={e}
              isUp={isUp}
              isBetActive={isBetActive}
              multiple={e}
              selectedMultiple={selectedMultiple}
              onClickChoice={onClickChoice}
            />
          ))}
        </ul>
        <div className="btn-box">
          <button
            type="button"
            className={classNames('btn', { win: isUp, lose: !isUp })}
            onClick={handleClickBet}
            disabled={isBetActive}>
            <strong>Bet on {isUp ? 'Rising' : 'Drop'} Close</strong>
            <span>{currency(defaultPrice * selectedMultiple)} USDT</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default BetPrice;
