import { GameContractAddress } from '~def/game-contract-def';
import './docs.scss';

function Docs() {
  const feedbackURL = import.meta.env.VITE_FEEDBACK_URL;
  const scanURL = import.meta.env.VITE_SCAN_URL;

  return (
    <div id="docs">
      <ul>
        <li>
          <h3>BBM(BullBearMatch)?</h3>
          <p>
            Welcome to the anonymous betting arena where you can wager with a variety of people on whether the NASDAQ
            index will close higher or lower. Don&apos;t leave it up to luck, bet on your skills in predicting the stock
            market. And the bigger you bet, the larger the slice of the pie you could receive - it&apos;s that simple!
          </p>
          {/* <a href={feedbackURL} target="_blank" rel="noreferrer">
            Submit feedback &gt;&gt;
          </a> */}
        </li>
        <li>
          <h3>Settlement and allocation</h3>
          <p>
            The winner is determined based on the NASDAQ index&apos;s closing price, with the winner receiving a portion
            of the loser&apos;s bet proportional to their own stake. Transactions are executed through smart contracts
            for a secure and reliable settlement process without human intervention.
          </p>
        </li>
        <li>
          <h3>Contract address</h3>
          <p>{GameContractAddress}</p>
          <a href={scanURL} target="_blank" rel="noreferrer">
            Check the explorer &gt;&gt;
          </a>
        </li>
      </ul>
    </div>
  );
}

export default Docs;
