import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import gameReducer, { isGameInfo } from './game';
import gameParticipantsReducer, { isGameParticipants } from './game-participants';
import gameTotalAmountReducer, { isGameTotalBetAmount } from './game-total-amount';
import gameWinnersReducer, { isGameWinners } from './game-winners';
import uiReducer from './ui';
import walletReducer, { isWalletInfo } from './wallet';

export const store = configureStore({
  reducer: {
    ui: uiReducer,
    game: gameReducer,
    gameParticipants: gameParticipantsReducer,
    gameTotalAmount: gameTotalAmountReducer,
    gameWinners: gameWinnersReducer,
    wallet: walletReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

//////////////////////////////////////////////////////////////////////

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

//////////////////////////////////////////////////////////////////////

export const useWalletInfo = () => {
  return useAppSelector(({ wallet: { state: state } }) => (isWalletInfo(state) ? state : undefined));
};

export const useGameInfo = () => {
  return useAppSelector(({ game: { state: state } }) => (isGameInfo(state) ? state : undefined));
};

export const useGameParticipants = () => {
  return useAppSelector(({ gameParticipants: { state: state } }) => (isGameParticipants(state) ? state : undefined));
};

export const useGameTotalBetAmount = () => {
  return useAppSelector(({ gameTotalAmount: { state: state } }) => (isGameTotalBetAmount(state) ? state : undefined));
};

export const useGameWinners = () => {
  return useAppSelector(({ gameWinners: { state: state } }) => (isGameWinners(state) ? state : undefined));
};
