function Banner() {
  return (
    <div className="banner">
      <a href="">
        <img src="" />
      </a>
    </div>
  );
}

export default Banner;
