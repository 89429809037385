import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TokenContractDecimal } from '~def/token-contract-def';
import { getTotalBetAmount } from '../module/api';
import { commonInitState, extraReducersWrapper, isSynced, type StateType, type ThunkAPIConfig } from '~store/util';
import { dividendDecimal } from '~util/base';

export type GameTotalAmountState = StateType<number>;

export const isGameTotalBetAmount = isSynced<number>;

////////////////////////////////////////////////////////

const initialState = commonInitState<GameTotalAmountState>();

export const connectGameTotalBetAmount = createAsyncThunk<number, void, ThunkAPIConfig>(
  'connectGameTotalBetAmount',
  async () => {
    return dividendDecimal(await getTotalBetAmount(), TokenContractDecimal as number);
  },
);

export const gameTotalAmountSlice = createSlice({
  name: 'game-total-amount',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    extraReducersWrapper<number>(connectGameTotalBetAmount, builder);
  },
});

export default gameTotalAmountSlice.reducer;
