import classNames from 'classnames';
import LiveBetDto from '~dto/live-bet-dto';
import Coin from '~component/coin/coin';
import { currency, shortAddress } from '~util/base';
import './live-bets.scss';

interface LiveBetsItemProps {
  type: string;
  item: LiveBetDto;
  myAddress?: string;
}

function LiveBetsItem({ type, item, myAddress }: LiveBetsItemProps) {
  return (
    <tr className={classNames({ isMine: item.player === myAddress, [type]: item.player === myAddress })}>
      <td>{shortAddress(item.player)}</td>
      <td>
        {currency(Math.abs(item.betAmount))}
        <Coin type={'usdt'} />
      </td>
    </tr>
  );
}

export default LiveBetsItem;
