import { ReactElement } from 'react';

export interface HistoryDataMsgProps {
  size: number;
  children: ReactElement | string;
}

const HistoryDataMsg = ({ size, children }: HistoryDataMsgProps) => {
  return (
    <tr>
      <td colSpan={size} style={{ height: '100px', textAlign: 'center' }}>
        {children}
      </td>
    </tr>
  );
};

export default HistoryDataMsg;
