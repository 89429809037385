import { toast as reactToastify, ToastContainer as ReactToastifyContainer, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const toast = {
  notify: (txt: string, opt?: ToastOptions) => reactToastify.info(txt, opt),
  loading: (txt?: string, opt?: ToastOptions) => reactToastify.loading(txt ?? 'Please wait...', opt),
  error: (txt: string, opt?: ToastOptions) => reactToastify.error(txt, opt),
  sucess: (txt: string, opt?: ToastOptions) => reactToastify.success(txt, opt),
  warn: (txt: string, opt?: ToastOptions) => reactToastify.warn(txt, opt),
  dismise: () => reactToastify.dismiss(),
};

export const ToastContainer = () => {
  return (
    <>
      <ReactToastifyContainer
        position="top-center"
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnHover
        theme="dark"
      />
    </>
  );
};
