import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

interface ErrorBoundaryProps {
  children: JSX.Element;
}

const ErrorBoundaryWrap = ({ children }: ErrorBoundaryProps) => {
  return <ErrorBoundary fallback={<h1>Something went wrong.</h1>}>{children}</ErrorBoundary>;
};

export default ErrorBoundaryWrap;
