import GameError from '~component/game-error/game-error';
import Progress from '~component/progress/progress';
import { useAppSelector } from '~store/make-store';
import GameFetch from './game-fetch';

function Game() {
  const status = useAppSelector(({ game: { state: status } }) => status);

  if (status === 'pending') {
    return <Progress />;
  }
  if (status instanceof Error) {
    return <GameError />;
  }

  return <GameFetch />;
}

export default Game;
