import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TokenContractDecimal } from '~def/token-contract-def';
import WinnerDto from '~dto/winner-dto';
import { getApiGame, getWinnerList } from '../module/api';
import { commonInitState, extraReducersWrapper, isSynced, type StateType, type ThunkAPIConfig } from '~store/util';
import { dividendDecimal } from '~util/base';

export type GameWinnersState = StateType<WinnerDto[]>;

export const isGameWinners = isSynced<WinnerDto[]>;

////////////////////////////////////////////////////////

const initialState = commonInitState<GameWinnersState>();

export const connectGameWinnerList = createAsyncThunk<WinnerDto[], void, ThunkAPIConfig>(
  'connectGameWinnerList',
  async () => {
    const winnerList = await getWinnerList();
    const gameInfo = await getApiGame();

    const roundWinner = winnerList.filter((e) => e.round === gameInfo.round - 1);
    const groupByWinner = roundWinner.reduce(
      (prev, curr) => {
        const fIdx = prev.findIndex((e) => e.player === curr.player);

        // round: parseInt(e.round),
        // cnt: parseInt(e.cnt),
        // player: e.player,
        // orgAmount: parseInt(e.orgAmount),
        // winnerAmount: parseInt(e.winnerAmount),
        // isHigh: e.isHigh,

        if (fIdx > -1) {
          prev[fIdx].orgAmount += curr.orgAmount;
          prev[fIdx].winnerAmount += curr.winnerAmount;
        } else {
          prev.push(curr);
        }

        return prev;
      },
      [] as typeof roundWinner,
    );

    const list = groupByWinner
      .map((e) =>
        WinnerDto.create(
          e.player,
          'usdt',
          dividendDecimal(e.orgAmount, TokenContractDecimal),
          'usdt',
          dividendDecimal(e.winnerAmount, TokenContractDecimal),
        ),
      )
      .reverse();

    return list;
  },
);

export const gameWinnersSlice = createSlice({
  name: 'game-winners',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    extraReducersWrapper<WinnerDto[]>(connectGameWinnerList, builder);
  },
});

export default gameWinnersSlice.reducer;
