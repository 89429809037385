import BigNumber from 'bignumber.js';
import { BettingStatus } from '~def/base';
import { GameContractAddress } from '~def/game-contract-def';
import GameContract from './game-contract';
import TokenContract from './token-contract';

export const getBalanceOfAccount = async (account: string) => {
  return (await (await TokenContract.getInstance()).methods.balanceOf(account).call()) as string;
};

////////////////////////////////////////////////////////////

export const getApiGame = async () => {
  const result = (await (await GameContract.getInstance()).methods.getGame().call()) as {
    round: string;
    betAmount: string;
    bettingStatus: string;
    rate: string;
    usdtTokenAddress: string;
    usdtTokenDecimals: string;
  };

  const bettingStatus = (
    result.bettingStatus === '0' ? 'betting' : result.bettingStatus === '1' ? 'bet-done' : 'calculating'
  ) as BettingStatus;

  return {
    round: parseInt(result.round),
    betAmount: parseInt(result.betAmount),
    bettingStatus: bettingStatus,
    rate: parseInt(result.rate, 10) / 100,
    usdtTokenAddress: result.usdtTokenAddress,
    usdtTokenDecimals: parseInt(result.usdtTokenDecimals),
  };
};

export const setApiAllowance = async (myAddress: string) => {
  return (await (await TokenContract.getInstance()).methods.allowance(myAddress, GameContractAddress).call()) as number;
};

export const setApiApprove = async (myAddress: string, cnt: number, betAmount: string) => {
  const value = new BigNumber(betAmount).multipliedBy(Math.abs(cnt)).toString();

  // token 컨트랙한테 게임에서 돈을 꺼낼건데 그걸 허용해줘
  return await (await TokenContract.getInstance()).methods.approve(GameContractAddress, value).send({
    from: myAddress,
  });
};

export const setApiBetting = async (myAddress: string, betRound: number, cnt: number) => {
  return await (await GameContract.getInstance()).methods
    .createBet(betRound, Math.abs(cnt), cnt > 0)
    .send({ from: myAddress });
};

export const getApiParticipantList = async () => {
  const result = (await (await GameContract.getInstance()).methods.getParticipants().call()) as {
    round: string;
    cnt: string;
    player: string;
    orgAmount: string;
    isHigh: boolean;
  }[];

  return result.map((e) => {
    return {
      round: parseInt(e.round),
      cnt: parseInt(e.cnt),
      player: e.player,
      orgAmount: parseInt(e.orgAmount) * (e.isHigh ? 1 : -1),
      isHigh: e.isHigh,
    };
  });
};

export const getTotalBetAmount = async () => {
  const result = (await (await GameContract.getInstance()).methods.getTotalBetAmount().call()) as string;

  return parseInt(result, 10);
};

export const getWinnerList = async () => {
  const result = (await (await GameContract.getInstance()).methods.getWinners().call()) as {
    round: string;
    cnt: string;
    player: string;
    orgAmount: string;
    winnerAmount: string;
    isHigh: boolean;
  }[];

  return result.map((e) => {
    return {
      round: parseInt(e.round),
      cnt: parseInt(e.cnt),
      player: e.player,
      orgAmount: parseInt(e.orgAmount),
      winnerAmount: parseInt(e.winnerAmount),
      isHigh: e.isHigh,
    };
  });
};
