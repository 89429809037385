import { toast } from '~module/toast';
import { useAppDispatch, useAppSelector } from '~store/make-store';
import { connectMetaMaskWallet } from '~store/wallet';

const useLinkWallet = () => {
  const appDispatch = useAppDispatch();
  const walletState = useAppSelector(({ wallet: { state } }) => state);
  const isPending = walletState === 'pending';

  const handleClickConnect = () => {
    appDispatch(connectMetaMaskWallet())
      .unwrap()
      .catch(() => {
        toast.dismise();
        toast.error('Unable to use it because it is not connected to your wallet.');
      });
  };

  return {
    isPending,
    handleClickConnect,
  };
};

export default useLinkWallet;
