import { Contract, ContractSendMethod } from 'web3-eth-contract';
import GameContractABI, { GameContractAddress } from '~def/game-contract-def';
import Web3Connect, { Web3ConnectProvider } from '~module/web3-connect';

interface GameContractABI extends Contract {
  methods: {
    createBet(betRound: number, value: number, isHigh: boolean): ContractSendMethod;
    getGame(): ContractSendMethod;
    getParticipants(): ContractSendMethod;
    getTotalBetAmount(): ContractSendMethod;
    getWinners(): ContractSendMethod;
  };
}

class GameContract {
  static #instance: GameContractABI;

  static #provider?: Web3ConnectProvider;

  public static async getInstance() {
    const web3 = await Web3Connect.getInstance();

    if (this.#provider !== Web3Connect.provider) {
      this.#provider = Web3Connect.provider;
      this.#instance = new web3.eth.Contract(GameContractABI, GameContractAddress) as GameContractABI;
    }

    return this.#instance;
  }
}

export default GameContract;
