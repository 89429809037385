import Web3 from 'web3';

/**
 * Web3.givenProvider: 메타마스크로 호출
 * Web3.HttpProvider: RPC 다이렉트 호출
 */

export type Web3ConnectProvider = 'web' | 'metamasek';

class Web3Connect {
  static #instance: Web3;

  static provider?: Web3ConnectProvider;

  public static async getInstance() {
    if (!this.#instance) {
      const provider = new Web3.providers.HttpProvider(import.meta.env.VITE_RPC_URL);
      this.#instance = new Web3(provider);
      this.provider = 'web';
    }
    return this.#instance;
  }

  public static changeMetaMaskProvider() {
    this.#instance = new Web3(Web3.givenProvider);
    this.provider = 'metamasek';
  }
}

export default Web3Connect;
