import classNames from 'classnames';
import Coin from '~component/coin/coin';
import { currency } from '~util/base';
import useBetPriceItem from './bet-price-item.hook';
import './bet-price.scss';

interface BetPriceItemProps {
  isUp: boolean;
  isBetActive: boolean;
  multiple: number;
  selectedMultiple: number;
  onClickChoice: (multiple: number) => void;
}

function BetPriceItem({ isUp, isBetActive, multiple, selectedMultiple, onClickChoice }: BetPriceItemProps) {
  const { balance, coin, defaultPrice } = useBetPriceItem();

  const handleClickChoice: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();

    const target = e.currentTarget;
    const multiple = parseInt(target.dataset?.['multiple'] ?? '1');
    onClickChoice(multiple);
  };

  return (
    <li>
      <button
        type="button"
        data-multiple={multiple}
        onClick={handleClickChoice}
        className={classNames({ selected: selectedMultiple === multiple, win: isUp, lose: !isUp })}
        disabled={defaultPrice * multiple > balance || isBetActive}>
        <span className="bet-price">
          <strong>{currency(defaultPrice * multiple)}</strong>
          <Coin type={coin} />
        </span>
        <span className="bet-size">{`Bet x${multiple}.0`}</span>
      </button>
    </li>
  );
}

export default BetPriceItem;
