import classNames from 'classnames';
import LiveBetDto from '~dto/live-bet-dto';
import { useGameParticipants, useWalletInfo } from '~store/make-store';
import LiveBetsItem from './live-bets-item';
import './live-bets.scss';

function LiveBets() {
  const participants = useGameParticipants() ?? [];
  const myAddress = useWalletInfo()?.address;
  const list = {
    win: participants.filter((e) => e.betAmount >= 0),
    lose: participants.filter((e) => e.betAmount < 0),
  };

  return (
    <div id="live-bets">
      <h3>Live Bets</h3>
      <div className="bet-box">
        {Object.entries(list).map(([key, value]: [string, LiveBetDto[]]) => {
          return (
            <div key={key} className={classNames('bet-table', key)}>
              <div className="table-wrap">
                <table>
                  <thead>
                    <tr>
                      <th scope="column">Player</th>
                      <th scope="column">Bet Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {value.map((e, idx) => (
                      <LiveBetsItem key={`${e.player}-${idx}`} type={key} item={e} myAddress={myAddress} />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default LiveBets;
