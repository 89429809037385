import './loading.scss';

interface LoadingProps {
  size?: number;
  thick?: number;
  color?: 'white' | 'black';
}

const Loading = ({ size = 20, thick = 5, color = 'white' }: LoadingProps) => {
  return (
    <div
      className="loader"
      style={{
        width: size + 'px',
        height: size + 'px',
        border: `${thick}px solid ${color === 'white' ? color : '#23252a'}`,
        borderBottomColor: 'transparent',
        borderRadius: '50%',
        display: 'inline-block',
        boxSizing: 'border-box',
        animation: 'rotation 0.5s linear infinite',
      }}></div>
  );
};

export default Loading;
