import './footer.scss';

// import LangSelect from './lang-select';

function Footer() {
  return (
    <div id="footer">
      <address>
        <h2 className="ico logo">BullBearMatch</h2>
        <p>
          BBM(BullBearMatch) is not responsible for any cryptocurrency prices <br />
          and investment-related information on the site. Please note <br />
          that investing in digital assets is entirely at your own risk.
        </p>
        <p>©2023 BBM ALL RIGHTS RESERVED</p>
      </address>
      {/* <LangSelect /> */}
    </div>
  );
}

export default Footer;
