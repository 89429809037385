import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TokenContractDecimal } from '~def/token-contract-def';
import { CoinType } from '~types';
import { getBalanceOfAccount } from '~module/api';
import { commonInitState, extraReducersWrapper, isSynced, type StateType, type ThunkAPIConfig } from '~store/util';
import { dividendDecimal } from '~util/base';
import Web3Connect from '~module/web3-connect';

export type WalletState = StateType<WalletInfo>;

interface WalletInfo {
  address: string;
  balance: number;
  coin: CoinType;
}

export const isWalletInfo = isSynced<WalletInfo>;

////////////////////////////////////////////////////

export class MetaMaskChainDisconnect extends Error {}

export class MetaMaskNoExistAccount extends Error {}

/////////////////////////////////////////////////////

const initialState = commonInitState<WalletState>();

export const connectMetaMaskWallet = createAsyncThunk<void, void, ThunkAPIConfig>(
  'connectMetaMaskWallet',
  async (_, { dispatch }) => {
    if (window.ethereum == null) {
      throw new MetaMaskNoExistAccount();
    }

    const chainId = parseInt(import.meta.env.VITE_ETHEREUM_CHAIN_ID, 10);
    const chainIdTxt = `0x${chainId.toString(16)}`;
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: chainIdTxt }],
      });

      const account = await window.ethereum.request<unknown>({ method: 'eth_requestAccounts' });

      Web3Connect.changeMetaMaskProvider();
      dispatch(updateAddress(account));
    } catch (err) {
      const errCode = (err as any).data?.originalError?.code || (err as any)?.code;
      if (errCode === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: chainIdTxt,
                chainName: import.meta.env.VITE_ETHEREUM_CHAIN_NAME,
                nativeCurrency: {
                  name: import.meta.env.VITE_ETHEREUM_CHAIN_CURRENCY_NAME,
                  symbol: import.meta.env.VITE_ETHEREUM_CHAIN_CURRENCY_SYMBOL,
                  decimals: parseInt(import.meta.env.VITE_ETHEREUM_CHAIN_CURRENCY_DECIMAL, 10),
                },
                rpcUrls: [import.meta.env.VITE_ETHEREUM_RPC_URL],
                blockExplorerUrls: ['https://explorer.binance.org/smart-testnet'],
              },
            ],
          });
        } catch {
          throw new MetaMaskChainDisconnect();
        }
      } else {
        throw new MetaMaskChainDisconnect();
      }
    }
  },
);

export const updateAddress = createAsyncThunk<WalletInfo, unknown, ThunkAPIConfig>(
  'updateAddress',
  async (addresses) => {
    if (!Array.isArray(addresses) || addresses.length === 0 || !addresses.every((e) => typeof e === 'string')) {
      throw new MetaMaskNoExistAccount();
    }

    const address = (addresses as string[])[0];
    const balance = await getBalanceOfAccount(address);
    const balanceByDecimal = dividendDecimal(balance, TokenContractDecimal);

    return {
      address: address,
      balance: balanceByDecimal,
      coin: 'usdt',
    } as WalletInfo;
  },
);

export const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(connectMetaMaskWallet.pending, (state) => {
      state.state = 'pending';
    });
    builder.addCase(connectMetaMaskWallet.rejected, (state, { payload }) => {
      state.state = payload ?? new Error();
    });

    extraReducersWrapper<WalletInfo, unknown>(updateAddress, builder);
  },
});

export default walletSlice.reducer;
