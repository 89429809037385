import { isWalletInfo } from '~store/wallet';
import { useAppSelector } from '~store/make-store';
import './header.scss';

const useHeader = () => {
  const walletState = useAppSelector(({ wallet: { state } }) => state);
  const isPending = walletState === 'pending';
  const isConnected = isWalletInfo(walletState);

  return {
    isPending,
    isConnected,
  };
};

export default useHeader;
