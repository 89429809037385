import { useEffect } from 'react';
import Banner from '~component/banner/banner';
import BetPrice from '~component/bet-price/bet-price';
import Docs from '~component/docs/docs';
import Footer from '~component/footer/footer';
import Game from '~component/game/game';
import Header from '~component/header/header';
import { connectGame } from '~store/game';
import { useAppDispatch, useAppSelector } from '~store/make-store';
import { updateAddress } from '~store/wallet';
import './i18n';
import './Main.scss';

function Main() {
  const appDispatch = useAppDispatch();
  const isBetting = useAppSelector(({ ui: { isBetting } }) => isBetting);

  useEffect(() => {
    appDispatch(connectGame());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const updateAccountAddress = (args: unknown) => {
      appDispatch(updateAddress(args));
    };
    window.ethereum?.on('accountsChanged', updateAccountAddress);

    return () => {
      window.ethereum?.removeListener('accountsChanged', updateAccountAddress);
    };
  }, [appDispatch]);

  return (
    <div id="Main">
      <Header />
      <Game />
      <Banner />
      <Docs />
      <Footer />
      {isBetting && (
        <>
          <div className="popup-bg"></div>
          <BetPrice isUp={isBetting.isUp} />
        </>
      )}
    </div>
  );
}

export default Main;
