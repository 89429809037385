import Coin from '~component/coin/coin';
import { currency, shortAddress } from '~util/base';
import useLinkedWallet from './linked-wallet.hook';
import './linked-wallet.scss';

function LinkedWallet() {
  const { balance, coin, address } = useLinkedWallet();

  return (
    <div id="linked-wallet">
      <div className="price">
        <div>
          <p>{currency(balance)}</p>
          <Coin type={coin} />
        </div>
      </div>
      <p className="btn type2">{shortAddress(address)}</p>
    </div>
  );
}

export default LinkedWallet;
