import BigNumber from 'bignumber.js';

export const currency = (num: number) => {
  const bn = new BigNumber(num);
  return '$' + bn.toFormat();
};

export const shortAddress = (address: string) => {
  return address.length > 11 ? `${address.substring(0, 5)}..${address.substring(address.length - 4)}` : address;
};

export const numToTimeStr = (num: number) => {
  let hours: number | string = Math.floor(num / 3600);
  let minutes: number | string = Math.floor((num - hours * 3600) / 60);
  let seconds: number | string = num - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }
  if (seconds < 10) {
    seconds = '0' + seconds;
  }

  return `${hours}:${minutes}:${seconds}`;
};

export const dividendDecimal = (balance: number | string, decimals: number) => {
  return new BigNumber(balance).div(10 ** decimals).toNumber();
};

export const multipleDecimal = (balance: number, decimals: number) => {
  return new BigNumber(balance).multipliedBy(10 ** decimals).toString();
};
