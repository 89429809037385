import React from 'react';
import { useGameWinners } from '~store/make-store';
import WinnerItem from './winner-item';
import './winners.scss';

function Winners() {
  const list = useGameWinners() ?? [];

  return (
    <div id="winners">
      <h2>
        <span>Winners</span> <span className="ico winners"></span>
        <span className="ico winners"></span>
      </h2>
      <div className="winner-table">
        <table>
          <thead>
            <tr>
              <th scope="column">Player</th>
              <th scope="column">Bet Amount</th>
              <th scope="column">Profit Amount</th>
            </tr>
          </thead>
          <tbody>
            {list.length > 0 ? (
              list.map((e, idx) => {
                return <WinnerItem key={`${e.player}-${idx}`} item={e} />;
              })
            ) : (
              <tr>
                <td colSpan={3} className="no-list">
                  There was no Winner 🥲
                  <br />
                  Total pot will be saved at{' '}
                  <a
                    href="https://testnet.bscscan.com/address/0xDDE535E150520c9F3D494F7561D405E83DD774bB#tokentxns"
                    target="blank">
                    Jackpot Wallet.
                  </a>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Winners;
