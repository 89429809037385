import { useAppSelector, useGameInfo } from '~store/make-store';
import { isWalletInfo } from '~store/wallet';

const useBetPriceItem = () => {
  const { balance, coin } = useAppSelector(({ wallet: { state: walletState } }) => ({
    balance: isWalletInfo(walletState) ? walletState.balance : 0,
    coin: isWalletInfo(walletState) ? walletState.coin : 'usdt',
  }));
  const defaultPrice = useGameInfo()?.betAmount ?? 0;

  return {
    balance,
    coin,
    defaultPrice,
  };
};

export default useBetPriceItem;
