import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TokenContractDecimal } from '~def/token-contract-def';
import LiveBetDto from '~dto/live-bet-dto';
import { getApiParticipantList } from '../module/api';
import { commonInitState, extraReducersWrapper, isSynced, type StateType, type ThunkAPIConfig } from '~store/util';
import { dividendDecimal } from '~util/base';

export type GameParticipantsState = StateType<LiveBetDto[]>;

export const isGameParticipants = isSynced<LiveBetDto[]>;

////////////////////////////////////////////////////////

const initialState = commonInitState<GameParticipantsState>();

export const connectGameParticipantList = createAsyncThunk<LiveBetDto[], void, ThunkAPIConfig>(
  'connectGameParticipantList',
  async () => {
    return (await getApiParticipantList())
      .map((e) => LiveBetDto.create(e.player, 'usdt', dividendDecimal(e.orgAmount, TokenContractDecimal)))
      .reverse();
  },
);

export const gameParticipantsSlice = createSlice({
  name: 'game-participants',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    extraReducersWrapper<LiveBetDto[]>(connectGameParticipantList, builder);
  },
});

export default gameParticipantsSlice.reducer;
