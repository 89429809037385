import { ContractSendMethod } from 'web3-eth-contract';
import { TokenContractABI, TokenContractAddress } from '~def/token-contract-def';
import Web3Connect, { Web3ConnectProvider } from './web3-connect';

// interface TokenContractABI extends Contract {
interface TokenContractABI {
  methods: {
    balanceOf(address: string): ContractSendMethod;
    decimals(): ContractSendMethod;
    approve(address: string, value: string): ContractSendMethod;
    allowance(ownerAddress: string, spenderAddress: string): ContractSendMethod;
  };
}

class TokenContract {
  static #instance: TokenContractABI;

  static #provider?: Web3ConnectProvider;

  public static async getInstance() {
    const web3 = await Web3Connect.getInstance();

    if (this.#provider !== Web3Connect.provider) {
      this.#provider = Web3Connect.provider;
      this.#instance = new web3.eth.Contract(TokenContractABI, TokenContractAddress) as TokenContractABI;
    }

    return this.#instance;
  }
}

export default TokenContract;
