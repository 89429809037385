import Loading from '~component/loading/loading';
import useHeader from './header.hook';
import './header.scss';
import LinkWallet from './link-wallet';
import LinkedWallet from './linked-wallet';

function Header() {
  const { isPending, isConnected } = useHeader();

  return (
    <div id="header">
      <a className="ico logo">BULL BEAR MATCH</a>
      {isPending ? <Loading thick={4} color="white" /> : isConnected ? <LinkedWallet /> : <LinkWallet />}
    </div>
  );
}

export default Header;
