import { toast } from '~module/toast';
import { useAppDispatch, useAppSelector } from '~store/make-store';
import { onBetting } from '~store/ui';

const useBetting = () => {
  const appDispatch = useAppDispatch();
  const isConnected = useAppSelector(
    ({ wallet: { state } }) => state !== 'idle' && state !== 'pending' && !(state instanceof Error),
  );

  const handleDisconnected = () => {
    toast.dismise();
    toast.error('Unable to use it because it is not connected to your wallet.');
  };

  const handleClickUp = (isBetting: boolean) => {
    if (isBetting) {
      if (isConnected) {
        appDispatch(onBetting({ isUp: true }));
      } else {
        handleDisconnected();
      }
    }
  };

  const handleClickDown = (isBetting: boolean) => {
    if (isBetting) {
      if (isConnected) {
        appDispatch(onBetting({ isUp: false }));
      } else {
        handleDisconnected();
      }
    }
  };

  return { isConnected, handleClickUp, handleClickDown };
};

export default useBetting;
