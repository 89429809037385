import { useEffect } from 'react';
import Betting from '~component/betting/betting';
import History from '~component/history/history';
import LiveBets from '~component/live-bets/live-bets';
import Winners from '~component/winners/winners';
import { connectGameParticipantList } from '~store/game-participants';
import { connectGameTotalBetAmount } from '~store/game-total-amount';
import { connectGameWinnerList } from '~store/game-winners';
import { useAppDispatch, useGameInfo } from '~store/make-store';

function GameFetch() {
  const appDispatch = useAppDispatch();
  const bettingStatus = useGameInfo()?.bettingStatus;

  useEffect(() => {
    appDispatch(connectGameTotalBetAmount());

    if (bettingStatus === 'calculating') {
      appDispatch(connectGameWinnerList());
    } else {
      appDispatch(connectGameParticipantList());
    }
  }, [appDispatch, bettingStatus]);

  return (
    <>
      <Betting />
      {bettingStatus === 'betting' && <LiveBets />}
      {bettingStatus === 'bet-done' && <LiveBets />}
      {bettingStatus === 'calculating' && <Winners />}
      <History />
    </>
  );
}

export default GameFetch;
