import BettingBtn from './betting-btn';
import BettingInfo from './betting-info';
import './betting.scss';

function Betting() {
  return (
    <div id="betting">
      <header>
        <span className="yellow-box"></span>
        <h1>Bet Crypto on NASDAQ!</h1>
        <p>What is your sentiment?</p>
        <span className="bull"></span>
        <span className="bear"></span>
      </header>
      <BettingInfo />
      <BettingBtn />
    </div>
  );
}

export default Betting;
