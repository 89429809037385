import classNames from 'classnames';
import type { CoinType } from '~types';
import './coin.scss';

interface CoinProps {
  type: CoinType;
}

function Coin({ type }: CoinProps) {
  return <span className={classNames('ico', 'coin', type)}></span>;
}

export default Coin;
