import classNames from 'classnames';
import { useGameInfo } from '~store/make-store';
import { currency } from '~util/base';
import './betting-btn.scss';
import useBetting from './betting.hook';

function BettingBtn() {
  const gameInfo = useGameInfo();
  const bettingStatus = gameInfo?.bettingStatus;
  const betAmount = gameInfo?.betAmount ?? 0;
  const { handleClickUp, handleClickDown } = useBetting();
  const isNotBetting = bettingStatus !== 'betting';

  const onClickUp: React.MouseEventHandler = (e) => {
    e.preventDefault();
    handleClickUp(bettingStatus === 'betting');
  };

  const onClickDown: React.MouseEventHandler = (e) => {
    e.preventDefault();
    handleClickDown(bettingStatus === 'betting');
  };

  return (
    <div id="betting-btn">
      <button type="button" className={classNames('btn', 'win')} onClick={onClickUp} disabled={isNotBetting}>
        <span className="icon"></span>
        <span className="title">BULLISH</span>
        <span className="bet-amount">1bet for {currency(betAmount)}</span>
      </button>
      <button type="button" className={classNames('btn', 'lose')} onClick={onClickDown} disabled={isNotBetting}>
        <span className="icon"></span>
        <span className="title">BEARISH</span>
        <span className="bet-amount">1bet for {currency(betAmount)}</span>
      </button>
    </div>
  );
}

export default BettingBtn;
