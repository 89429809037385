import { useQuery } from 'react-query';

const useHistoryData = () => {
  const { data } = useQuery<{ date: string; opened: string; closed: string }[]>(
    'history',
    () =>
      fetch(import.meta.env.VITE_HISTORY_URL).then((res) => {
        return res.json();
      }),
    {
      useErrorBoundary: true,
    },
  );

  const addNetChange = data?.reduce(
    (prev, curr) => {
      let netChange = 0 as number | string;

      if (prev.length > 0) {
        const prevItem = prev[prev.length - 1];
        const prevClose = parseFloat(prevItem.closed.replaceAll(',', ''));
        const currClose = parseFloat(curr.closed.replaceAll(',', ''));
        const priceChange = currClose - prevClose;

        netChange = (priceChange / prevClose) * 100;
        netChange = netChange.toFixed(2);
      }

      return [...prev, { ...curr, netChange }];
    },
    [] as typeof data,
  ) as { date: string; opened: string; closed: string; netChange: string }[];

  // 내림차순 정렬
  // 2023-10-09	-> 2023-10-06 -> 2023-10-05
  addNetChange?.sort((a, b) => (a.date < b.date ? 1 : -1));

  return { data: addNetChange?.slice(0, 10) };
};

export default useHistoryData;
