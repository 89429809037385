import { useWalletInfo } from '~store/make-store';
import './linked-wallet.scss';

const useLinkedWallet = () => {
  const wallet = useWalletInfo();
  const balance = wallet?.balance ?? 0;
  const coin = wallet?.coin ?? 'usdt';
  const address = wallet?.address ?? '';

  return {
    balance,
    coin,
    address,
  };
};

export default useLinkedWallet;
