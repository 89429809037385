import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import HistoryData from './history-data';
import HistoryDataErr from './history-data-err';
import HistoryDataLoading from './history-data-loading';
import './history.scss';

function History() {
  return (
    <div id="history">
      <h3>Historical data for last 10 days</h3>
      <table>
        <thead>
          <tr>
            <th scope="column">Date</th>
            <th scope="column">Opened</th>
            <th scope="column">Closed</th>
            <th scope="column">Change(%)</th>
          </tr>
        </thead>
        <tbody>
          <ErrorBoundary fallback={<HistoryDataErr size={4} />}>
            <Suspense fallback={<HistoryDataLoading size={4} />}>
              <HistoryData size={4} />
            </Suspense>
          </ErrorBoundary>
        </tbody>
      </table>
    </div>
  );
}

export default History;
