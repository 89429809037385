import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BettingStatus } from '~def/base';
import { TokenContractDecimal } from '~def/token-contract-def';
import { getApiGame } from '../module/api';
import { commonInitState, extraReducersWrapper, isSynced, type StateType, type ThunkAPIConfig } from '~store/util';
import { dividendDecimal } from '~util/base';

export type GameState = StateType<GameInfo>;

export type GameInfo = {
  round: number;
  betAmount: number;
  bettingStatus: BettingStatus;
  rate: number;
};

export const isGameInfo = isSynced<GameInfo>;

////////////////////////////////////////////////////////

const initialState = commonInitState<GameState>();

export const connectGame = createAsyncThunk<GameInfo, void, ThunkAPIConfig>('connectGame', async () => {
  const { betAmount, ...game } = await getApiGame();

  return {
    ...game,
    betAmount: dividendDecimal(betAmount, TokenContractDecimal),
  };
});

export const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    extraReducersWrapper<GameInfo>(connectGame, builder);
  },
});

export default gameSlice.reducer;
